import type { OrderSource, PersonalOrderPosition } from '@gks/prisma'
import type { VehicleType } from '@black-bird/types'
import { useMutation } from '@pinia/colada'

type NeuzulassungData = Parameters<ReturnType<typeof useNuxtApp>['$trpc']['personal']['neuzulassung']['createOrder']['mutate']>['0']['neuzulassung']

export default defineStore('neuzulassung', () => {
  const { email, phone } = storeToRefs(useSharedInfo())

  const data = ref<NeuzulassungData>({
    fin: '',
    scZb2: '',
    nummerZb2: '',
    bankAccount: {
      iban: ''
    },
    evbNummer: '',
    naechstesKennzeichen: {
      type: 'reservierung',
      elektro: false,
      pin: '',
      kombination: {
        token: '',
        letters: '',
        numbers: ''
      },
    }
  })

  const reservationBehoerdeId = ref<string | null>(null)

  const vehicle = ref<VehicleType>('car')

  const dto = computed(() => {
    const { fin, scZb2, naechstesKennzeichen, nummerZb2, evbNummer, bankAccount } = data.value

    const pin = naechstesKennzeichen.type === 'reservierung' ? (naechstesKennzeichen.pin || undefined) : undefined
    const naechstesKennzeichenKombination = naechstesKennzeichen.type === 'reservierung' ? naechstesKennzeichen.kombination : undefined
    const behoerdeId = naechstesKennzeichen.type === 'reservierung' && !naechstesKennzeichen.pin ? reservationBehoerdeId.value : undefined

    const selectedPlate = useWkzSearchesStore().selectedPlate
    const vehicleType = naechstesKennzeichen.type === 'reservierung' ? selectedPlate?.search?.vehicle : undefined

    return {
      email: email.value,
      source: useRuntimeConfig().public.source as OrderSource,
      phone: phone.value,
      neuzulassung: {
        fin,
        scZb2,
        // nummerZb2,
        bankAccount,
        evbNummer,
        naechstesKennzeichen: {
          type: naechstesKennzeichen.type,
          elektro: naechstesKennzeichen.elektro,
          pin,
          kombination: naechstesKennzeichenKombination
        }
      },
      vehicleType,
      reservationBehoerdeId: behoerdeId
    }
  })

  const licensePlatePositions = ref<PersonalOrderPosition[]>([])
  const umweltplakettePositions = ref<PersonalOrderPosition[]>([])

  const positions = computed<PersonalOrderPosition[]>(() => {
    // * Prevent positions if user selected zufallskennzeichen
    if (data.value.naechstesKennzeichen.type === 'zufall') return []
  
    return [
      ...licensePlatePositions.value,
      ...umweltplakettePositions.value
    ]
  })

  const { mutateAsync: createOrder, isLoading: createOrderLoading } = useMutation({
    mutation: async () => {
      // Sometimes people copy the url and try to create an order without the required data in local storage
      // In this case we should navigate the user to the start page and not let them create an order
      if (!dto.value.email) {
        return navigateTo('/', { replace: true })
      }

      const googleClientId = await getGoogleClientId()

      const { checkoutSessionUrl } = await useNuxtApp().$trpc.personal.neuzulassung.createOrder.mutate({
        ...dto.value,
        positions: positions.value,
        googleClientId
      })
    
      window.location.href = checkoutSessionUrl
      // fake wait until redirect happens
      await new Promise(res => setTimeout(res, 10000))
    }
  })

  return {
    data,
    dto,
    createOrder,
    createOrderLoading,
    reservationBehoerdeId,
    positions,
    licensePlatePositions,
    umweltplakettePositions,
    vehicle
  }
}, {
  persist: {
    storage: persistedState.localStorage,
    pick: ['data', 'reservationBehoerdeId', 'licensePlatePositions', 'umweltplakettePositions', 'vehicle']
  }
})
