import type { PlateType } from '@black-bird/types'

export default function getStrongPlateType (plateType: PlateType): PlateType {
  switch (plateType) {
    case 'green':
      return 'standard'
    default:
      return plateType
  }
}
