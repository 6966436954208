import type { NuxtApp } from '#app'

type BehoerdeWithExtras = Awaited<ReturnType<NuxtApp['$trpc']['public']['findBehoerden']['query']>>[number]

export default defineStore('shared-info', () => {
  const email = ref('')
  const phone = ref('')
  const behoerde = ref<BehoerdeWithExtras | null>(null)

  return {
    email,
    phone,
    behoerde
  }
}, {
  persist: {
    storage: persistedState.localStorage,
    pick: ['email', 'phone', 'behoerde']
  }
})
