import type { RouteLocationNormalizedLoaded} from 'vue-router'
import type { Search, WkzCheckoutPosition } from '@gks/types'
import { klona } from 'klona/json'

export default defineStore('wkz-checkout-store', () => {
  const order = ref(null)
  const positions = ref<WkzCheckoutPosition[]>([])

  const originOfSearch = ref<Partial<RouteLocationNormalizedLoaded> | null>(null)

  const holderData = ref({
    title: 'Herr',
    fullName: '',
    streetAndHouseNumber: '',
    additionalInfo: '',
    companyName: '',
    postalCode: '',
    city: '',
    email: '',
    birthDate: '',
    birthPlace: '',
    phoneNumber: ''
  })
  
  const shippingAddress = ref({
    fullName: '',
    streetAndHouseNumber: '',
    additionalInfo: '',
    companyName: '',
    postalCode: '',
    city: '',
  })

  const differentShippingAddress = ref(false)

  function startWkzCheckout (originalSearch: Search) {
    const search = klona(originalSearch)
    const sku = getSkuFromSearch(search)

    const wkzPosition: WkzCheckoutPosition = {
      sku,
      qty: getDefaultQtyForVehicle(search.vehicle),
      payload: {
        city: search.token,
        letters: search.letters,
        numbers: search.numbers,
        option: search.vehicle === 'tractor' ? 'green' : search.plateType,
        film: 'standard',
        size: search.plate.size,
        plateType: search.plateType,
        vehicle: getStrongVehicleType(search.vehicle),
        officeId: search.officeId  
      }
    }

    if (search.plateType === 'season' || search.plateType === 'oldtimer-season') {
      wkzPosition.payload.seasonFrom = search.plate.seasonFrom
      wkzPosition.payload.seasonTo = search.plate.seasonTo
    }

    clearPositions()
    addPosition(wkzPosition)
    setOriginOfSearch()

    useRouter().push('/wunschkennzeichen/checkout')
  }

  function addPosition (position: any) {
    positions.value.push(position)
  }

  function removePosition (index: number) {
    positions.value = klona(positions.value.filter((_, i) => i !== index))
  }

  function clearPositions () {
    positions.value = []
  }

  function setOriginOfSearch () {
    const { name, params, query } = useRoute()
    originOfSearch.value = {
      name: name,
      params,
      query
    }
  }

  function setOrder (newOrder) {
    order.value = newOrder
  }

  function returnToOriginOfSearch () {
    if (!originOfSearch.value) return useRouter().replace('/')

    return useRouter().push(originOfSearch.value)
  }

  const { wkzProductIds } = useProductIds()

  const wkzPosition = computed(() => {
    return positions.value
      .find(position => wkzProductIds.some(wkzId => position.sku.includes(wkzId)))
  })

  return {
    order,
    positions,
    originOfSearch,
    holderData,
    shippingAddress,
    differentShippingAddress,
    wkzPosition,
    startWkzCheckout,
    addPosition,
    removePosition,
    clearPositions,
    returnToOriginOfSearch,
    setOrder
  }
}, {
  persist: {
    storage: persistedState.localStorage
  }
})
