// import Hotjar from '@hotjar/browser'

export default defineNuxtPlugin(() => {
  // if (useRuntimeConfig().public.target !== 'production'){
  //   return
  // }
  
  // const siteId = 3099397
  // const hotjarVersion = 6
  
  // Hotjar.init(siteId, hotjarVersion)
})
