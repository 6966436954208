export const plateTypeOptions = [
  { label: 'Standard', value: 'standard' },
  { label: 'Saison', value: 'season' },
  { label: 'Historisch', value: 'oldtimer' },
  { label: 'Elektro', value: 'electric' },
  { label: 'H-Saison', value: 'oldtimer-season' }
] as const

export const vehicleOptions = [
  { label: 'PKW', value: 'car' },
  { label: 'Anhänger', value: 'trailer' },
  { label: 'Motorrad', value: 'motorcycle' },
  { label: 'Leichtkraftrad', value: 'light-motorcycle' },
  { label: 'LKW', value: 'lkw' },
  { label: 'Traktor (grün)', value: 'tractor' },
  { label: 'Steuerbefreit (grün)', value: 'green-car' }
] as const

export const monthOptions = [{
  value: 1,
  label: 'Januar'
}, {
  value: 2,
  label: 'Februar'
}, {
  value: 3,
  label: 'März'
}, {
  value: 4,
  label: 'April'
}, {
  value: 5,
  label: 'Mai'
}, {
  value: 6,
  label: 'Juni'
}, {
  value: 7,
  label: 'Juli'
}, {
  value: 8,
  label: 'August'
}, {
  value: 9,
  label: 'September'
}, {
  value: 10,
  label: 'Oktober'
}, {
  value: 11,
  label: 'November'
}, {
  value: 12,
  label: 'Dezember'
}] as const


export const suggestionTypeOptions = [
  { label: 'Alle', value: 'all' },
  { label: '100er', value: 'hundreds' },
  { label: '1000er', value: 'thousands' },
  { label: 'Schnapszahlen', value: 'brandy_pay' },
  { label: 'Gleiche Buchstaben', value: 'same_letters' },
  { label: 'Aufsteigende Buchstaben', value: 'rising_letters' },
  { label: 'Absteigende Buchstaben', value: 'falling_letters' },
  { label: 'Aufsteigende Zahlen', value: 'rising_numbers' },
  { label: 'Absteigende Zahlen', value: 'falling_numbers' },
  { label: 'Symmetrisch', value: 'symmetrical' },
  { label: '10er, 100er, 1000er', value: 'tens' },
  { label: 'Zahlenfolgen', value: 'number_follow' },
  { label: 'Zwillinge', value: 'twins' }
] as const
