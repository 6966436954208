import getSizesForCombination from '@black-bird/plate-size-generator'

export default function (combination, plateSize) {
  // light-motorcycle must be handeled like car for combination test
  combination.vehicle = combination.vehicle === 'light-motorcycle' ? 'car' : combination.vehicle

  combination.vehicle = getStrongVehicleType(combination.vehicle)

  combination.plateType = getStrongPlateType(combination.plateType)

  while (combination.numbers.length > 0) {
    const sizes = getSizesForCombination(combination)

    if (sizes.includes(plateSize)) break

    combination.numbers = combination.numbers.slice(0, -1)
  }

  return combination
}
