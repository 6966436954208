import type { PlateSize } from '@black-bird/types'

export const _520x110: PlateSize = '520x110'
export const _460x110: PlateSize = '460x110'
export const _420x110: PlateSize = '420x110'
export const _400x110: PlateSize = '400x110'
export const _255x130: PlateSize = '255x130'
export const _240x130: PlateSize = '240x130'
export const _220x200: PlateSize = '220x200'
export const _200x200: PlateSize = '200x200'
export const _180x200: PlateSize = '180x200'

export const carPlateSizes: PlateSize[] = [
  _520x110,
  _460x110,
  _420x110,
  _400x110
]

export const trailerSizes: PlateSize[] = [
  _520x110,
  _460x110,
  _420x110,
  _400x110
]

export const motorcyclePlateSizes: PlateSize[] = [
  _220x200,
  _200x200,
  _180x200
]

export const lightMotorcyclePlateSizes: PlateSize[] = [
  _240x130,
  _255x130
]

export const tractorPlateSizes: PlateSize[] = [
  _240x130,
  _255x130
]

export const plateSizes: PlateSize[] = [
  ...carPlateSizes,
  ...trailerSizes,
  ...motorcyclePlateSizes,
  ...lightMotorcyclePlateSizes,
  ...tractorPlateSizes
].reduce((pre, cur) => pre.includes(cur) ? pre : [...pre, cur], [] as PlateSize[])
