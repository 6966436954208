import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin(nuxtApp => {
  if (useRuntimeConfig().public.target !== 'production') return

  Sentry.init({
    Vue: nuxtApp.vueApp,
    dsn: 'https://390e28d454d00dfc627f0bca54043aea@o85766.ingest.us.sentry.io/4507152373841920',
    tracesSampleRate: 1.0,
    ignoreErrors:[
      'Form validation failed', // Ignore form validation errors from nuxt-ui
    ]
  })
})
